import { useState, useEffect } from 'react'
// https://stackoverflow.com/questions/60838781/controlling-browser-back-button-in-react

function useHashRouteToggle(modalHash) {
  const [isOpen, toggleOpen] = useState(false)

  const toggleOpenModal = (open) => {
    if (open) {
      window.location.assign(modalHash) // navigate to same url but with the specified hash
    } else {
      window.location.replace('#') // remove the hash
    }
  }

  useEffect(() => {
    const handleOnHashChange = () => {
      const isHashMatch = window.location.hash === modalHash
      toggleOpen(isHashMatch)
    }
    window.addEventListener('hashchange', handleOnHashChange)

    return () => window.removeEventListener('hashchange', handleOnHashChange)
  }, [modalHash])

  return [isOpen, toggleOpenModal]
}

export default useHashRouteToggle
