import { Box, Heading } from '@chakra-ui/react'
import React, { useState, useCallback } from 'react'
import { graphql } from 'gatsby'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react'
import PublicationCorrectionPdf from 'src/components/pdf/publication-correction-pdf'
import { PublicationCorrectionsBlock } from '@/components'
import useHashRouteToggle from 'src/components/pdf/useHashRouteToggle'

const PublicationCorrectionsPage = (props) => {
  const {
    data: {
      page: {
        flexLayouts: { flexibleLayouts },
      },
    },
  } = props

  const [isOpen, toggleOpen] = useHashRouteToggle('#pdf')
  const [pdfModalInfo, setPdfModalInfo] = useState({
    title: null,
    pdfLink: null,
  })

  const closeModalWithEmbeddedPDF = useCallback(() => {
    setPdfModalInfo({
      title: null,
      pdfLink: null,
    })
    toggleOpen(false)
  }, [])

  const openModalWithEmbeddedPDF = useCallback((pdfLink, title) => {
    toggleOpen(true)
    setPdfModalInfo({
      title,
      pdfLink,
    })
  }, [])

  return (
    <>
      {flexibleLayouts &&
        flexibleLayouts?.map((card) => {
          const { title, pdflinks } = card
          return (
            <PublicationCorrectionsBlock
              title={title}
              pdflinks={pdflinks}
              openModalWithEmbeddedPDF={openModalWithEmbeddedPDF}
              key={title}
              {...props}
            >
              <Box className="px-4 md:px-0">
                {title && (
                  <Heading
                    dangerouslySetInnerHTML={{ __html: title }}
                    fontSize="l"
                    lineHeight={1.33}
                    mb={10}
                  />
                )}
              </Box>
            </PublicationCorrectionsBlock>
          )
        })}
      {/* PDF Modal */}
      <Modal
        size="full"
        isOpen={isOpen}
        onClose={closeModalWithEmbeddedPDF}
        className="m-4"
      >
        <ModalContent className="my-0 ">
          <ModalHeader
            className="font-bold text-white bg-blue font-heading"
            style={{ fontSize: '1.2em' }}
          >
            {pdfModalInfo?.title}
          </ModalHeader>
          <ModalCloseButton color="white" style={{ fontSize: '24px' }} />
          <ModalBody
            className="flex justify-center content-center h-full p-0 m-0"
            style={{ padding: '0px', margin: '0px' }}
          >
            {pdfModalInfo?.pdfLink && (
              <PublicationCorrectionPdf
                url={pdfModalInfo?.pdfLink?.file?.slug}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export const publicationCorrectionsPageQuery = graphql`
  query WpPagePublicationCorrectionsTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          ... on WpPage_Flexlayouts_FlexibleLayouts_PublicationCorrectionsBlock {
            title
            pdflinks {
              file {
                id
                link
                title
                uri
                mediaItemUrl
                slug
              }
            }
          }
        }
      }
    }
  }
`

export default PublicationCorrectionsPage
